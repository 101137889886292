import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//3cb5c79118bb0d0dd551c7b2cc231e42 

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

 
